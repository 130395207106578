<template>
  <!-- 表单管理 -->
  <div class="formment-box">
    <div class="box">
      <div class="top-nav">
        <div class="title">文章管理</div>
        <div>
          <el-button type="primary" size="medium" @click="addmanagement">
            <i class="el-icon-plus fontweight9"></i
            ><span class="fontweight4">添加文章</span>
          </el-button>
          <el-button type="primary" size="medium" @click="choosefile">
            <i class="el-icon-plus fontweight9"></i
            ><span class="fontweight4">添加新分类目录</span>
          </el-button>
        </div>
      </div>
      <div class="search-box">
        <div class="search-input">
          <el-input v-model="search" placeholder="请输入搜索关键词"></el-input>
          <i class="el-icon-search"></i>
        </div>
        <el-button @click="searchClick">搜索</el-button>
      </div>
      <div>
        <infotable
          :tableHeadConfig="formMenttitle"
          :selection="false"
          align="left"
          :tableLoadData="tablelist"
        >
          <template v-slot:sort="slotData">
            <div class="order-box">
              <span>{{ slotData.data.row.sort }}</span>
              <img
                src="@/assets/images/icon/modify-pen.png"
                class="img"
                @click="editimgclcik(slotData.data.row)"
              />
            </div>
          </template>
          <template v-slot:is_display="slotData">
            <el-switch
              v-model="slotData.data.row.is_display"
              @change="selectshow(slotData.data.row)"
            ></el-switch>
          </template>
          <template v-slot:top="slotData">
            <el-switch v-model="slotData.data.row.top"></el-switch>
          </template>
          <template v-slot:tui="slotData">
            <el-switch v-model="slotData.data.row.tui"></el-switch>
          </template>
          <template v-slot:operation="slotData">
            <div class="oprtion-style">
              <div class="oper-modify" @click="edit(slotData.data.row)">
                编辑
              </div>
              <div class="border-box"></div>
              <div class="oper-del" @click="del(slotData.data.row)">删除</div>
            </div>
          </template>
        </infotable>
      </div>
      <div class="bottom-pagination">
        <bottom-pagination
          @pageclcik="pageclcik"
          :pagination="pagination"
        ></bottom-pagination>
      </div>
    </div>
    <el-dialog title="分类目录" :visible.sync="dialogVisiblefile" width="30%">
      <!-- <div style="display: flex">
        <el-input
          v-model="createfilename"
          placeholder="请输入分类目录"
        ></el-input>
      </div> -->
      <div
        style="
          display: flex;
          align-items: center;
          height: 30px;
          background: #fbf9f9;
          padding: 0 10px;
          margin: 10px 0;
        "
      >
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><span @click="choosefile">目录</span></el-breadcrumb-item
          >
          <el-breadcrumb-item v-for="(item, index) in level_text" :key="index">
            <span @click="choosefiles(item, index)">{{
              item[index + 1].name
            }}</span></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div style="display: flex; align-items: center; flex-wrap: wrap">
        <div
          v-for="(item, index) in filelist"
          :key="index"
          style="margin: 5px; border: 1px solid #dcdfe6"
        >
          <el-button
            size="small"
            @click="chooseitemfile(item)"
            style="border: 0"
            >{{ item.name }}</el-button
          >
          <i class="el-icon-edit" @click="editfile(item)"></i>
        </div>
        <div v-if="!filelist.length" style="height: 40px; line-height: 40px">
          文件夹为空
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblefile = false">取 消</el-button>
        <el-button type="primary" @click="adddialogVisiblefile=true">添 加</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="是否修改文件夹"
      :visible.sync="editdialogVisiblefile"
      width="30%"
    >
      <div style="display: flex" v-if="showdel(editdialogdata.name)">
        <el-input
          v-model="editdialogdata.name"
          placeholder="请输入名称"
        ></el-input>
      </div>
           <el-upload
                class="upload-demo"
                :action="url"
                :limit="10"
                :on-success="uploadchange"
                :on-error="uploadchange"
                :show-file-list="false"
                list-type="picture"
              >
              
               <img :src="editdialogdata.icon" style="width:100px;height:100px" v-if="editdialogdata.icon"/>
              <div style="width:100px;height:100px;border:1px solid #ccc;padding: 10px;color:#ccc;border-radius: 5px;" v-else>
                <i class="el-icon-plus" style="font-size: 50px;margin-bottom:3px"></i>
                <div>添加图片</div>
                </div>
              </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editdialogVisiblefile = false">取 消</el-button>
        <el-button type="primary" @click="editfile('修改')">确 定</el-button>
        <el-button type="danger" @click="editfile('删除')" v-if="showdel(editdialogdata.name)">删 除</el-button>
      </span>
    </el-dialog>

<!-- 添加分类 -->
    <el-dialog  title="添加文件夹"
      :visible.sync="adddialogVisiblefile"
      width="30%">
   <div style="display: flex" v-if="showdel(editdialogdata.name)">
        <el-input
          v-model="createfilename"
          placeholder="请输入名称"
        ></el-input>
      </div>
           <el-upload
                class="upload-demo"
                :action="url"
                :limit="10"
                :on-success="uploadchange"
                :on-error="uploadchange"
                :show-file-list="false"
                list-type="picture"
              >
              <img :src="icon" style="width:100px;height:100px" v-if="icon">
              <div style="width:100px;height:100px;border:1px solid #ccc;padding: 10px;color:#ccc;border-radius: 5px;" v-else>
                <i class="el-icon-plus" style="font-size: 50px;margin-bottom:3px"></i>
                <div>添加图片</div>
                </div>
          </el-upload>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cacleadddialogVisiblefile">取 消</el-button>
        <el-button type="primary" @click="createfile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination/pagination.vue";
import infotable from "@/components/infoTable/infoTable.vue";
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
  data() {
    return {
      adddialogVisiblefile:false,
      icon:'',
      search: "",
      platform_id: "",
      createfilename: "",
      level_text: [],
      dialogVisiblefile: false,
      editdialogVisiblefile: false,
      editdialogdata: {},
      pagination: {},
      page: 1,
      stopflag: false,
      tablelist: [],
      url:'',
      filelist: [],
      formMenttitle: [
        {
          label: "ID",
          field: "id",
          sort: true,
        },
        // {
        //   label: "排序",
        //   columnType: "slot",
        //   field: "sort",
        //   slotName: "sort",
        //   sort: true,
        // },
        {
          label: "标题",
          field: "name",
        },
        {
          label: "浏览量",
          field: "volume",
        },
        {
          label: "发布时间",

          field: "time",
        },
        {
          label: "是否显示",
          columnType: "slot",
          slotName: "is_display",
        },
        // {
        //   label: "置顶",
        //   field: "top",
        //   columnType: "slot",
        //   slotName: "top",
        // },
        // {
        //   label: "推荐",
        //   field: "tui",
        //   columnType: "slot",
        //   slotName: "tui",
        // },
        {
          label: "操作",
          field: "operation",
          columnType: "slot",
          slotName: "operation",
        },
      ],
    };
  },
  computed: {
    showswitch() {
      return function (val) {
        return val ? true : false;
      };
    },
    showdel(){
       return  function (val) {
         let flag=true
         if(val=='品牌与营销'||val=='热门产品'||val=='新品推荐'||val=='解决方案'||val=='写字板的安装与编辑'||val=='合作共赢'){
          flag=false
         }
        return flag
      };
    }
  },
  components: {
    infotable,
    "bottom-pagination": pagination,
  },
  mounted() {
    const platform_id = sessionGetKey("platformId");
    this.url =this.$uploadurl+ '?platform_id='+platform_id;
    this.platform_id = platform_id;
    this.getlist();
  },
  methods: {
    cacleadddialogVisiblefile(){
        this.createfilename = "";
            this.adddialogVisiblefile = false
            this.icon=""
    },
       uploadchange(res, file, fileList) {
      console.log(res, "----", file);

      if (res.code == 0) {
        this.icon=res.data
        let img = {
          name: file.name,
          url: file.response.data,
        };
       this.editdialogdata.icon=file.response.data
      } else {
        this.$message("上传失败:" + file.response.msg);
      }
    },
    selectshow(e) {
      console.log(e);
      this.$request({
        url:
          this.$api.articleApi.setarticle + `?platform_id=${this.platform_id}`,
        method: "post",
        data: e,
      }).then((res) => {
        if (res.code == 0) {
          // that.$message.success("保存成功");
        }
        console.log(res);
      });
    },
    editfile(e) {
      console.log(e);

      this.editdialogVisiblefile = true;
      const platform_id = sessionGetKey("platformId");
      let that = this;
      if (e == "修改") {
        let data = {
          id: this.editdialogdata.id,
          name: this.editdialogdata.name,
          icon:this.editdialogdata.icon
        };
        this.$request({
          url: this.$api.articleApi.setarticle + `?platform_id=${platform_id}`,
          method: "post",
          data: data,
        }).then((res) => {
          if (res.code == 0) {
            this.editdialogVisiblefile = false;
            this.choosefile();
            that.$message.success("成功");
          }
        });
      } else if (e == "删除") {
        let data = {
          id: this.editdialogdata.id,
        };

        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that
              .$request({
                url:
                  that.$api.articleApi.delarticle +
                  `?platform_id=${platform_id}`,
                method: "post",
                data: data,
              })
              .then((res) => {
                if (res.code == 0) {
                  that.editdialogVisiblefile = false;
                  that.choosefile();
                  that.$message.success("删除成功");
                }
              });
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.editdialogdata = e;
        // this.choosefile()
      }
    },
    choosefiles(e, i) {
      this.level_text.splice(i + 1);
      this.choosefile(e);
    },
    chooseitemfile(e) {
      const platform_id = sessionGetKey("platformId");
      this.dialogVisiblefile = true;
      let that = this;
      let obj = new Object();
      obj[this.level_text.length + 1] = {id:e.id,name:e.name};
      if (this.stopflag) {
        this.level_text.push(obj);
        this.stopflag = false;
      }

      console.log(this.level_text);
      this.$request({
        url:
          this.$api.articleApi.getarticlelevel + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          id: e.id,
        },
      })
        .then((res) => {
          that.filelist = res.data;
          this.stopflag = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    choosefile(e) {
      const platform_id = sessionGetKey("platformId");
      this.dialogVisiblefile = true;
      let that = this;
      this.stopflag = true;
      let data = {
        type: 2,
      };
      this.$request({
        url: this.$api.articleApi.getarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: data,
      })
        .then((res) => {
          console.log(res);
          this.filelist = res.data;
          this.level_text = [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdateTime(val = new Date()) {
      let year = new Date(val).getFullYear();
      let month = new Date(val).getMonth() + 1;
      let day = new Date(val).getDate();
      let hour = new Date(val).getHours();
      let Minutes = new Date(val).getMinutes();
      let Seconds = new Date(val).getSeconds();
      return `${year}-${month > 9 ? month : "0" + month}-${
        day > 9 ? day : "0" + day
      } ${hour > 9 ? hour : "0" + hour}:${
        Minutes > 9 ? Minutes : "0" + Minutes
      }:${Seconds > 9 ? Seconds : "0" + Seconds}`;
    },
    createfile(e) {
      //  添加文件夹
      let that = this;
      const platform_id = sessionGetKey("platformId");
      let data = {
        name: this.createfilename,
        time: "",
        platform_id: platform_id,
        icon:this.icon
      };
      if(!this.createfilename||!this.icon){
        this.$message('文件夹名称或图片不为空')
return
      }
      let obj = new Object();
      if (this.level_text.length) {
        this.level_text.forEach((val, index) => {
          obj[index + 1] = val[index + 1].id;
        });
        data.level_text = JSON.stringify([obj]);
      }
      console.log(data.level_text);

      data.time = this.getdateTime();
      this.$request({
        url: this.$api.articleApi.addarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: data,
      })
        .then((res) => {
          if (res.code == 0) {
            this.choosefile()
            that.$message.success("成功");
            that.createfilename = "";
            that.adddialogVisiblefile = false
            this.icon=""
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error(err.message);
          console.log(err);
        });
    },
    editimgclcik(e) {
      console.log(e);
    },
    edit(e) {
      this.$router.push("addarticleMent");
      console.log(e);
      window.localStorage.setItem("setarticle", JSON.stringify(e));
    },
    del(e) {
      const platform_id = sessionGetKey("platformId");
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that
            .$request({
              url:
                that.$api.articleApi.delarticle + `?platform_id=${platform_id}`,
              method: "post",
              data: {
                id: e.id,
              },
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                that.getlist();
              }
            })
            .catch((err) => {});
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
      console.log(e);
    },
    btnclick(e) {
      console.log(e);
    },
    calcelclick() {},
    addmanagement() {
      this.$router.push("addarticleMent");
      window.localStorage.setItem("setarticle", JSON.stringify(null));
    },
    searchClick() {
      let that = this;
      const platform_id = sessionGetKey("platformId");

      this.$request({
        url: this.$api.articleApi.getarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          type: 0,
          name: this.search,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          that.tablelist = res.data;
          that.tablelist.filter((val) => {
            val.is_display = val.is_display ? true : false;
          });
        }
        that.search = "";
      });
    },
    pageclcik(e) {
      this.page = e;
      console.log(e, "page");
      this.getlist();
    },
    getlist() {
      let that = this;
      const platform_id = sessionGetKey("platformId");
      this.$request({
        url: this.$api.articleApi.getarticle + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          type: 0,
          page: that.page,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            that.pagination = {
              currentpage: res.data.current_page,
              total: res.data.total,
              totalpage: res.data.last_page,
              pageSize: res.data.per_page,
            };
            that.tablelist = res.data.data;
            that.tablelist.filter((val) => {
              val.is_display = val.is_display ? true : false;
            });
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.formpopup {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-demo{
  margin:10px auto; 
  width: 100px;
}
.fontweight9 {
  font-weight: 600;
}
.fontweight4 {
  font-weight: 400;
  margin-left: 3px !important;
}
.formment-box {
  height: 100%;
  padding: 66px;
  min-height: 600px;
  .border-box {
    width: 1px;
    height: 18px;
    background: #4458fe;
    margin: 0 10px;
  }
  .oprtion-style {
    display: flex;
    align-items: center;
  }
  .oper-modify {
    color: #4458fe;
  }
  .oper-del {
    color: #4458fe;
  }
  .box {
    .order-box {
      display: flex;
      .img {
        margin-left: 5px;
        width: 22px;
        height: 22px;
        // vertical-align: bottom;
      }
    }
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    min-height: 100%;
    // height: 100%;
    background: #fff;
    padding: 33px;
    .top-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        padding-bottom: 10px;
        color: #4458fe;
        font-size: 20px;
        font-weight: 600;
        border-bottom: #4458fe 2px solid;
      }
    }

    .search-box {
      margin: 21px 0;
      display: flex;
      align-items: center;
      .search-input {
        margin-right: 20px;
        padding: 0 15px;
        border-radius: 6px;
        width: 400px;
        box-sizing: border-box;
        border: 1px solid #999;
        display: flex;
        align-items: center;
        /deep/.el-input__inner {
          border: 0 !important;
          padding: 0 !important;
        }
        .el-icon-search {
          font-size: 20px;
          color: #999;
        }
      }
      .el-button {
        border: #4458fe 1px solid;
        background-color: #4458fe !important;
        color: #ffffff;
      }
    }
  }
  .bottom-pagination {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 30px;
    margin-top: 20px;
  }
}
</style>